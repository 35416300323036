.wrapper {
  position: relative;
}

.container {
  width: 390px;
  height: 52px;
  border-radius: 5px;
}

.incorrectInput {
  border: 1px solid red;
}

.errorContainer {
  position: absolute;
}
.errorContainer p {
  color: var(--red);
  font-size: 12px;
}

@media (max-width: 1919px) {
  .container {
    width: 255px;
    height: 39px;
  }

  .errorContainer p {
    font-size: 10px;
  }
}

@media (max-width: 1365px) {
  .container {
    width: 224px;
    height: 36px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
