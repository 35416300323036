.modal {
  width: 600px;
  height: 600px;
  background-image: url('../../images/FailedPopup.svg');
  background-repeat: no-repeat;
  background-position: 55px 153px;
}
.title {
  width: 411px;
  font-size: 36px;
  line-height: 44px;
}
.button {
  width: 334px;
}

@media (max-width: 1919px) {
  .modal {
    width: 480px;
    height: 480px;
    background-position: 40px 113px;
    background-size: 400px 243px;
  }
  .title {
    width: 269px;
    height: 320px;
    font-size: 24px;
    line-height: 29px;
  }
  .button {
    width: 235px;
  }
}

@media (max-width: 1365px) {
  .modal {
    width: 360px;
    height: 360px;
    background-position: 32px 86px;
    background-size: 296px 180px;
  }
  .title {
    width: 217px;
    height: 222px;
    font-size: 18px;
    line-height: 22px;
  }
  .button {
    width: 203px;
  }
}

@media (max-width: 767px) {
  .modal {
    width: 335px;
    height: 335px;
    background-position: 24px 73px;
    background-size: 285px 182px;
  }
  .title {
    width: 243px;
    height: 222px;
    font-size: 18px;
    line-height: 22px;
  }
  .button {
    width: 287px;
  }
}
