.form {
  display: grid;
  grid-row-gap: 24px;
  grid-template-rows: 52px 52px 20px 52px 60px;
  grid-template-columns: 1fr;
}

.title {
  grid-row: 1;
  grid-column: 1;
  font-size: 36px;
}

.openForgot {
  grid-row: 3;
  color: #c4c4c4;
  text-align: left;
  background: var(--white);
  border: none;
  outline: none;
}

.button {
  grid-row: 4;
}

.openSignUp {
  margin-left: 6px;
  color: var(--blue);
  font-weight: var(--bold);
  outline: none;
}

@media (max-width: 1919px) {
  .title {
    font-size: 24px;
  }
  .form {
    grid-row-gap: 16px;
    grid-template-rows: 39px 39px 20px 39px 60px;
  }

  .text {
    margin-top: 12px;
  }
}

@media (max-width: 1365px) {
  .title {
    font-size: 18px;
  }
  .form {
    grid-template-rows: 36px 36px 12px 36px 36px;
  }
  .text {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .text {
    text-align: center;
  }

  .form {
    grid-template-rows: 36px 36px 12px 36px 12px;
  }
}
