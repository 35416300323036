.form {
  display: grid;
  grid-row: 3;
  grid-row-gap: 40px;
  grid-template-rows: 52px 52px 52px;
  grid-template-columns: 1fr;
}

.title {
  grid-row: 1;
  grid-column: 1;
  font-size: 36px;
}

.button {
  grid-row: 3;
}

.description {
  grid-row: 2;
  width: 390px;
}

@media (max-width: 1919px) {
  .title {
    font-size: 24px;
  }
  .form {
    grid-row-gap: 16px;
    grid-template-rows: 39px 39px 39px;
  }
  .description {
    width: 260px;
  }
  .button {
    margin-top: 24px;
  }
}

@media (max-width: 1365px) {
  .title {
    font-size: 18px;
  }
  .form {
    grid-row-gap: 16px;
    grid-template-rows: 36px 36px 36px;
  }
  .description {
    width: 224px;
  }
}

@media (max-width: 1023px) {
  .button {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .description {
    width: 100%;
  }
  .button {
    margin-top: 0;
  }
}
