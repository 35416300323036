.container {
  display: grid;
  grid-template-columns: 741px 741px;
  gap: 96px;
  justify-content: center;
  justify-content: center;
  margin-top: 96px;
}

.container > section:nth-child(2) {
  margin-top: 197px;
}

.container > section:nth-child(odd) {
  margin-top: -394px;
}
.container > section:nth-child(1) {
  position: relative;
  margin-top: -197px;
}

.container > section:nth-child(1)::before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 101px;
  height: 101px;
  background-image: url('../../images/PatternBlueMiddle.svg');
  background-position: 100% 100%;
  content: ' ';
}

.container > section:nth-child(1) {
  position: relative;
}

.container > section:nth-child(1)::after {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 0;
  width: 50vw;
  height: 101px;
  background-image: url('../../images/PatternGrayMiddle.svg');
  background-position: 0 100%;
  content: ' ';
}

.container > section:nth-child(6)::after {
  position: absolute;
  right: 100%;
  bottom: 0;
  z-index: 0;
  width: 100vw;
  height: 101px;
  background-image: url('../../images/PatternGrayMiddle.svg');
  background-position: calc(100% - 18px) 100%;
  content: ' ';
}

.blue {
  background-color: #f3f7fc;
}

.gray {
  background-color: #f9f9f9;
}

.white {
  background-color: #fff;
  border: 4px solid #e5e5e5;
  box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.1);
}

.gray li:before {
  background-color: #59afec;
}
.white li:before {
  background-color: #c4c4c4;
}

@media (max-width: 1919px) {
  .container {
    grid-template-columns: 530px 530px;
    gap: 56px;
    margin-top: 56px;
  }
  .container > section:nth-child(2) {
    margin-top: 140px;
  }

  .container > section:nth-child(odd) {
    margin-top: -280px;
  }
  .container > section:nth-child(1) {
    position: relative;
    margin-top: -140px;
  }
  .container > section:nth-child(1)::before {
    width: 74px;
    height: 74px;
    background-size: 32px;
  }
  .container > section:nth-child(1)::after,
  .container > section:nth-child(6)::after {
    height: 74px;
    background-size: 32px;
  }
}

@media (max-width: 1365px) {
  .container {
    grid-template-columns: 424px 424px;
  }
  .container > section:nth-child(2) {
    margin-top: 110px;
  }

  .container > section:nth-child(odd) {
    margin-top: -220px;
  }
  .container > section:nth-child(1) {
    position: relative;
    margin-top: -110px;
  }
  .container > section:nth-child(1)::before {
    width: 54px;
    height: 54px;
    background-size: 23.5px;
  }
  .container > section:nth-child(1)::after,
  .container > section:nth-child(6)::after {
    height: 54px;
    background-size: 23.5px;
  }
}

@media (max-width: 1023px) {
  .container {
    grid-template-columns: 424px;
  }
  .container > section:nth-child(1)::after {
    display: none;
  }
  .container > section:nth-child(2) {
    margin-top: 0;
  }
  .container > section:nth-child(odd) {
    margin-top: 0;
  }
  .container > section:nth-child(1) {
    margin-top: 0;
  }
  .container > section:nth-child(1)::before {
    width: 54px;
    height: 54px;
    background-size: 23.5px;
  }
  .container > section:nth-child(6)::after {
    height: 54px;
    background-size: 23.5px;
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-columns: 338px;
    row-gap: 26px;
  }
  .container > section:nth-child(1)::before {
    width: 54px;
    height: 54px;
    background-size: 23.5px;
  }
  .container > section:nth-child(1)::after,
  .container > section:nth-child(6)::after {
    height: 54px;
    background-size: 23.5px;
  }
}
