.container {
  position: relative;
  display: block;
  grid-column: 1/3;
  margin-bottom: 12px;
  padding-left: 24px;
  color: #c4c4c4;
  cursor: pointer;
  user-select: none;
}

.blackLabel {
  color: #2c2c2c;
}

.errorLabel {
  color: var(--red);
}

.container input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}

.errorCheckmark {
  border: 1px solid var(--red);
}

.container:hover input ~ .checkmark {
  background-color: #e5e5e5;
}

.container input:checked ~ .checkmark {
  background-color: var(--green);
  border: none;
}

.checkmark:after {
  position: absolute;
  display: none;
  content: '';
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 3px;
  left: 4px;
  width: 6px;
  height: 6px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

@media (max-width: 1919px) {
  .container {
    padding-left: 14px;
  }
  .checkmark {
    top: 3px;
    width: 9px;
    height: 9px;
  }

  .container .checkmark:after {
    left: 3px;
    width: 3px;
    height: 3px;
  }
}

@media (max-width: 1365px) {
  .checkmark {
    top: 1px;
  }
}

@media (max-width: 767px) {
  .container {
    grid-column: 1;
  }
}
