.container {
  display: grid;
  grid-template-rows: 380px 41px 195px 99px 52px 318px;
  grid-template-columns: 1fr 178px 526px 94px 705px 417px 1fr;
  /* overflow-x: hidden; */
}

.number {
  grid-row: 3;
  grid-column: 3;
  font-weight: var(--bold);
  font-size: 160px;
  text-align: center;
}

.text {
  grid-row: 4;
  grid-column: 3;
  font-weight: var(--medium);
  font-size: 36px;
  line-height: 44px;
}

.button {
  grid-row: 5;
  grid-column: 3;
  color: #4455a1;
  font-weight: var(--medium);
  border: 1px solid #4455a1;
  border-radius: 2px;
}

.button:hover {
  background-color: #e3e6f1;
}

.image {
  position: relative;
  grid-row: 2/7;
  grid-column: 5;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
}

/* .shadow {
  position: relative;
  z-index: -1;
  grid-row: 2/7;
  grid-column: 5;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  margin: 50px;
  background: #2c2c2c;
  border-radius: 6px;
  filter: blur(90px);
} */
.square {
  position: relative;
  top: -178px;
  left: -154px;
  z-index: -1;
  grid-row: 2/7;
  grid-column: 5;
  width: 420px;
  height: 420px;
  background: rgba(176, 203, 234, 0.15);
}
.square::before {
  position: absolute;
  width: 630px;
  height: 630px;
  background-image: url('../../images/PatternBlueBig.svg');
  content: ' ';
}

.square::after {
  position: absolute;
  top: 358px;
  right: -614px;
  width: 210px;
  height: 630px;
  background-image: url('../../images/PatternBlueBig.svg');
  content: ' ';
}

@media (max-width: 1919px) {
  .container {
    grid-template-rows: 235px 23px 151px 76px 39px 218px;
    grid-template-columns: 1fr 136px 410px 56px 507px 257px 1fr;
  }
  .number {
    font-size: 124px;
    line-height: 151px;
  }
  .text {
    font-size: 28px;
    line-height: 34px;
  }
  .square {
    top: -128px;
    left: -110px;
    width: 300px;
    height: 300px;
  }
  .square::before {
    width: 450px;
    height: 450px;
    background-size: 156px;
  }
  .square::after {
    top: 260px;
    right: -460px;
    width: 150px;
    height: 450px;
    background-size: 150px;
  }
}

@media (max-width: 1365px) {
  .container {
    grid-template-rows: 250px 38px 119px 51px 36px 196px;
    grid-template-columns: 1fr 60px 322px 60px 440px 142px 1fr;
  }
  .number {
    font-size: 98px;
    line-height: 119px;
  }
  .text {
    font-size: 22px;
    line-height: 27px;
  }
  .square {
    top: -110px;
    left: -96px;
    width: 260px;
    height: 260px;
  }
  .square::before {
    width: 414px;
    height: 414px;
    background-size: 138px;
  }
  .square::after {
    top: 207px;
    right: -400px;
    width: 138px;
    height: 414px;
    background-size: 138px;
  }
}

@media (max-width: 1023px) {
  .container {
    grid-template-rows: 82px 119px 134px 468px 40px 36px;
    grid-template-columns: 1fr 22vw 468px 17vw 1fr;
  }
  .number {
    grid-row: 2;
    grid-column: 3;
    font-size: 98px;
  }
  .text {
    grid-row: 3;
    grid-column: 3;
    font-size: 22px;
    line-height: 27px;
    text-align: center;
  }
  .button {
    grid-row: 6;
    grid-column: 3;
    justify-self: center;
    width: 322px;
  }
  .image {
    position: relative;
    grid-row: 4;
    grid-column: 3;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /* .shadow {
    grid-row: 4;
    grid-column: 3;
  } */
  .square {
    top: -120px;
    left: -100px;
    grid-row: 4;
    grid-column: 3;
    width: 280px;
    height: 280px;
  }
  .square::before {
    width: 420px;
    height: 420px;
    background-size: 140px;
  }
  .square::after {
    top: 245px;
    right: -421px;
    width: 140px;
    height: 420px;
    background-size: 140px;
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-rows: 26px 64px 56px 255px 16px 40px;
    grid-template-columns: 1fr 65px 255px 55px 1fr;
  }
  .number {
    font-size: 54px;
    line-height: 65px;
  }
  .text {
    font-size: 12px;
    line-height: 15px;
  }
  .button {
    justify-self: center;
    width: 100%;
  }
  .square {
    top: -62px;
    left: -52px;
    grid-row: 4;
    grid-column: 3;
    width: 150px;
    height: 150px;
  }
  .square::before {
    width: 225px;
    height: 225px;
    background-size: 75px;
  }
  .square::after {
    top: 130px;
    right: -180px;
    width: 44px;
    height: 225px;
    background-size: 75px;
  }
}
