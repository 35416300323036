:root {
  --width: 509px;
  --gap: 174px;
  --left: calc(-1 * var(--width));
  --first: calc(50vw - var(--width) - var(--gap) / 2);
  --second: calc(50vw + var(--gap) / 2);
  --right: 100%;
  --time: 10s;
}

@media (max-width: 1919px) {
  :root {
    --width: 362px;
    --gap: 98px;
  }
}

@media (max-width: 1365px) {
  :root {
    --width: 324px;
    --gap: 56px;
  }
}

@media (max-width: 1023px) {
  :root {
    --width: 284px;
    --gap: 60px;
  }
}

.name {
  margin-top: 172px;
  margin-left: 300px;
  font-size: 48px;
  line-height: 59px;
}

.container {
  position: relative;
  width: 100vw;
  height: 888px;
  overflow: hidden;
  background: #f9f9f9;
  box-shadow: inset 0px 0px 19px rgba(0, 0, 0, 0.25);
}

.item {
  position: absolute;
  top: 359px;
  left: var(--left);
  width: var(--width);
  min-height: 265px;
  transition: left 0.5s;
}

.first {
  left: var(--first);
}

.second {
  left: var(--second);
}

.right {
  left: var(--right);
  opacity: 0;
}

@media (max-width: 1919px) {
  .name {
    margin-top: 120px;
    margin-left: 244px;
    font-size: 36px;
    line-height: 44px;
  }
  .container {
    height: 672px;
  }
  .item {
    top: 254px;
  }
}

@media (max-width: 1365px) {
  .name {
    margin-top: 140px;
    margin-left: 120px;
    font-size: 24px;
    line-height: 29px;
  }
  .container {
    height: 628px;
  }
  .item {
    top: 274px;
  }
}

@media (max-width: 1023px) {
  .name {
    margin-top: 104px;
    margin-left: 80px;
    font-size: 24px;
    line-height: 29px;
  }
  .container {
    height: 592px;
  }
  .item {
    top: 238px;
  }
}

@media (max-width: 767px) {
  :root {
    --width: 247px;
    --gap: 0px;
    --left: calc(-1 * var(--width));
    --first: calc(50vw - var(--width) / 2);
    --second: 120vw;
    --right: 120vw;
  }
  .container {
    height: 500px;
    background: #fff;
    box-shadow: none;
  }
  .name {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
  }
  .item {
    top: 122px;
    margin-right: -50%;
  }
}
