.header {
  position: fixed;
  top: 0;
  z-index: 50;
  display: flex;
  width: 100vw;
  height: 166px;
  padding: 24px 105px 24px 130px;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 1);
  transition: height 0.5s;
}

.header.small {
  height: 90px;
}

.logo {
  display: block;
  width: 212px;
  height: 104px;
  padding: 0;
  background-image: url('../../images/HeaderLogo.svg');
  background-repeat: no-repeat;
  transition: width 0.5s, height 0.5s, background-size 0.5s;
}

.logo:focus {
  outline: none;
}

.small .logo {
  width: 114px;
  height: 56px;
  /* background-size: 114px 56px; */
}

.itemsContainer {
  display: flex;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nav ul {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.nav li {
  margin: 0 8px;
  padding: 8px 16px;
  list-style: none;
}

.nav a {
  color: #2c2c2c;
  text-decoration: none;
  list-style: none;
}

/* .nav li.login {
  margin-top: -4px;
  padding: 0;
} */

.line {
  display: block;
  width: 1px;
  height: 38px;
  margin: 0 24px;
  background-color: #2c2c2c;
}

.contacts {
  margin-top: -4px;
}
.nav li.mail {
  padding: 0;
  font-weight: var(--light);
}

.nav li.phone {
  margin-top: 8px;
  padding: 0;
}

.menu {
  display: none;
}

@media (max-width: 1365px) {
  .header {
    height: 96px;
    padding: 32px 60px;
  }
  .itemAppear {
    display: block;
  }
  .nav ul {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
  .item {
    display: none;
  }
  .nav li {
    padding: 12px 8px;
  }
  /* .nav li.login {
    margin-top: 2px;
  } */
  .line {
    height: 28px;
    margin-top: 5px;
  }
  .contacts {
    margin-top: 2px;
  }
  .mobileMenu {
    position: absolute;
    top: 16px;
    right: 32px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }

  .mobileMenu li {
    display: flex;
    align-items: center;
    width: 207px;
    height: 51px;
    padding: 0 18px;
    border-top: 1px solid #e5e5e5;
    cursor: pointer;
  }

  .mobileMenu li:hover {
    font-weight: var(--bold);
  }

  .mobileMenu li:first-child {
    border-top: none;
  }
  .mobileMenu a {
    color: black;
    font-weight: var(--light);
    font-size: 12px;
  }
  .menu {
    position: relative;
    display: block;
    padding: 10px 17px 10px 31px;
    color: var(--blue);
    font-weight: var(--bold);
    background-image: url('../../images/HeaderMenu.svg');
    background-repeat: no-repeat;
    background-position: 17px 14px;
    border: 1px solid var(--blue);
    border-radius: 2px;
    outline: none;
  }

  .logo {
    width: 130px;
    height: 64px;
    background-repeat: no-repeat;
  }
}

/* @media (max-width: 1023px) {
  .nav li.login {
    margin-top: 1px;
  }
} */

@media (max-width: 767px) {
  .header,
  .header.small {
    height: 60px;
    padding: 0 20px;
  }
  .logo {
    /* background-size: 57px 28px; */
    width: 57px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .small .logo {
    width: 57px;
    height: 28px;
    /* background-size: 114px 56px; */
  }
  .phone,
  .mail,
  .line {
    display: none;
  }

  .mobileMenu {
    top: 60px;
    right: 12px;
    width: 207px;
    border-radius: 0 0 5px 5px;
  }

  .mobileMenu li {
    width: calc(100% - 18px);
  }
  .menu {
    padding: 16px;
    font-size: 0;
    background-image: url('../../images/HeaderMenuMobile.svg');
    background-position: 8px;
    border: none;
  }
}
