.footer {
  position: relative;
  padding-top: 134px;
  background: #f9f9f9;
  box-shadow: inset 0px 0px 19px rgba(0, 0, 0, 0.25);
}
.container {
  padding: 172px 208px 131px 130px;
}
.form {
  grid-row: 1;
}
.list {
  display: grid;
  grid-column: 2;
  grid-template-columns: 337px 291px 356px 1fr;
  list-style-type: none;
}
.list li {
  margin-bottom: 16px;
  list-style-type: none;
}
.logo {
  display: block;
  width: 212px;
  height: 104px;
  padding: 0;
  background-image: url('../../images/HeaderLogo.svg');
  background-repeat: no-repeat;
}
.title {
  margin-bottom: 16px;
  font-weight: 600;
}
.list a {
  color: #000;
  text-decoration: none;
}
.fingers {
  display: block;
  margin-top: 37px;
  padding-left: 87px;
  background-image: url('../../images/fingers.png');
  background-repeat: no-repeat;
}

@media (max-width: 1919px) {
  .footer {
    padding-top: 104px;
  }
  .container {
    padding: 96px 80px 40px;
  }
  .list {
    grid-template-columns: 230px 204px 271px 1fr;
    column-gap: 0px;
  }
  .logo {
    width: 140px;
    height: 69px;
  }
  .fingers {
    display: block;
    margin-top: 27px;
    padding-left: 71px;
    background-size: 63px 15px;
  }
}

@media (max-width: 1365px) {
  .footer {
    padding-top: 80px;
  }
  .list {
    grid-template-columns: 144px 164px 220px 1fr;
    column-gap: 0px;
  }
  .logo {
    width: 82px;
    height: 40px;
  }
  .fingers {
    display: block;
    margin-top: 28px;
    padding-left: 59px;
    background-size: 51px 12px;
  }
}

@media (max-width: 1023px) {
  .footer {
    padding-top: 96px;
  }
  .list {
    grid-template-columns: 162px 197px 1fr;
    column-gap: 0px;
  }
  .logo {
    width: 82px;
    height: 40px;
  }
  .fingersContainer {
    grid-row: 2;
    grid-column: 2;
  }
  .fingers {
    display: block;
    margin-top: 8px;
    padding-left: 59px;
    background-size: 51px 12px;
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-columns: 1fr;
    padding: 64px 40px 34px;
    row-gap: 22px;
  }
  .list {
    grid-template-columns: 156px 1fr;
    column-gap: 0px;
  }
  .list li {
    margin-bottom: 24px;
  }
  .list li li {
    margin-bottom: 16px;
  }
  .logoContainer {
    grid-column: 1/3;
    margin-left: -20px;
  }
  .logo {
    width: 82px;
    height: 40px;
  }

  .fingersContainer {
    grid-row: 3;
    grid-column: 1/3;
  }
  .fingers {
    display: block;
    margin-top: 8px;
    padding-left: 59px;
    background-size: 51px 12px;
  }
}
