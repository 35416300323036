.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  zoom: 1;
}

.container {
  display: grid;
  grid-template-rows: 1fr max-content 1fr;
  grid-template-columns: 1fr max-content 1fr;
  height: 100vh;
}

.content {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: grid;
  grid-row: 2;
  grid-column: 2;
  grid-template-columns: 320px 1fr;
  width: 1252px;
  height: 568px;
  list-style: none;
  background-color: var(--white);
  border-radius: 4px;
}

.signinContent,
.forgotContent,
.createContent {
  width: 902px;
  height: 532px;
}

.contactContent {
  grid-template-columns: 1fr;
  width: 1194px;
  height: max-content;
}

.image {
  grid-column: 1;
  background-image: url('../../images/ModalBackground.png');
  border-radius: 4px 0 0 4px;
}

.signinImage,
.forgotImage,
.createImage {
  height: 532px;
}

.form {
  display: grid;
  grid-template-rows: 60px 80px 264px 52px;
  grid-template-columns: 1fr 1fr;
  margin: 56px;
}

.signinForm {
  grid-template-rows: 60px 60px 356px;
  grid-template-columns: 1fr;
}

.forgotForm {
  grid-template-rows: 1fr 100px 144px;
  grid-template-columns: 1fr;
  margin: 114px 136px 114px 56px;
}

.createForm {
  grid-template-rows: 1fr 60px 200px;
  grid-template-columns: 1fr;
  margin: 96px 56px;
}

.contactForm {
  grid-row-gap: 40px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
}

@media (max-width: 1919px) {
  .content {
    grid-template-columns: 236px 1fr;
    width: 858px;
    height: 418px;
  }

  .contactContent {
    height: max-content;
  }

  .signinContent,
  .forgotContent,
  .createContent {
    width: 629px;
    height: 395px;
  }
  .image {
    height: 418px;
    background-size: cover;
  }
  .signinImage,
  .forgotImage,
  .createImage {
    height: 395px;
  }
  .form {
    grid-template-rows: 40px 62px 236px;
    margin: 40px;
  }
  .signinForm {
    grid-template-rows: 40px 47px 236px;
  }

  .forgotForm {
    grid-template-rows: 41px 77px 144px;
    margin: 83px 98px 83px 40px;
  }

  .contactForm {
    grid-template-rows: 1fr;
    margin-bottom: 60px;
  }
}

@media (max-width: 1365px) {
  .content {
    grid-template-columns: 209px 1fr;
    width: 753px;
    height: 370px;
  }

  .contactContent {
    height: max-content;
  }

  .signinContent,
  .forgotContent,
  .createContent {
    width: 547px;
    height: 342px;
  }
  .image {
    height: 370px;
  }
  .signinImage,
  .forgotImage,
  .createImage {
    height: 342px;
  }
  .form {
    grid-template-rows: 34px 48px 224px;
    margin: 32px;
  }
  .signinForm {
    grid-template-rows: 34px 36px 236px;
  }

  .forgotForm {
    grid-template-rows: 34px 60px 96px;
    margin: 76px 82px 76px 32px;
  }

  .createForm {
    margin: 56px 82px 56px 32px;
  }

  .contactForm {
    grid-template-rows: 1fr;
  }
}

@media (max-width: 1023px) {
  .content {
    grid-template-columns: 1fr;
    width: 544px;
    height: 370px;
  }

  .image {
    display: none;
  }

  .form {
    margin: 32px;
  }

  .signinContent,
  .forgotContent,
  .createContent {
    width: 338px;
    height: 342px;
  }

  .contactContent {
    width: 502px;
    height: max-content;
  }

  .forgotForm {
    margin: 76px 82px 76px 32px;
  }

  .createForm {
    margin: 56px 82px 56px 32px;
  }
  .contactForm {
    grid-row-gap: 24px;
    grid-template-rows: 1fr;
  }
}

@media (max-width: 767px) {
  .content {
    width: calc(100vw - 40px);
    height: max-content;
  }
  .form {
    grid-template-rows: 34px 34px max-content;
    grid-template-columns: 1fr;
    margin: 24px;
  }

  .forgotForm {
    grid-template-rows: 34px 56px max-content;
  }

  .contactForm {
    grid-template-rows: 1fr;
  }
}
