.form {
  display: grid;
  grid-row: 3;
  grid-row-gap: 24px;
  grid-column: 1/3;
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1fr;
}

.title {
  grid-row: 1/3;
  grid-column: 1;
  font-size: 36px;
}

.button {
  grid-row: 4;
}

@media (max-width: 1919px) {
  .title {
    font-size: 24px;
  }
  .form {
    grid-row-gap: 16px;
    grid-column-gap: 32px;
    grid-template-rows: 39px 39px 55px;
  }

  .button {
    grid-row: 3;
    margin-top: 16px;
  }
}

@media (max-width: 1365px) {
  .title {
    margin-top: 24px;
    font-size: 18px;
  }
  .form {
    grid-template-rows: 36px 36px 55px;
  }
}

@media (max-width: 767px) {
  .form {
    grid-column: 1;
    grid-template-rows: 36px;
    grid-template-columns: 1fr;
  }

  .button {
    grid-row: 5;
  }
}
