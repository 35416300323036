.container {
  display: grid;
  grid-template-rows: 400px 144px 138px 90px 299px;
  grid-template-columns: 241px 518px 99px 705px 483px;
  justify-content: center;
}

.header {
  grid-row: 2;
  grid-column: 2;
  font-weight: var(--medium);
  font-size: 36px;
}

.price,
.priceName {
  grid-row: 3;
  grid-column: 2/4;
  font-weight: var(--bold);
  font-size: 87px;
}
.priceName {
  font-size: 48px !important;
  line-height: 87px;
}

.text {
  grid-row: 5;
  grid-column: 2;
  max-width: 719px;
  margin-bottom: 62px;
  color: #646464;
  font-size: 18px;
}

.sliderContainer {
  position: relative;
  grid-row: 4;
  grid-column: 2;
}

.slider {
  width: 100%;
  height: 66px;
  background-color: transparent;
  outline: none;
  -webkit-appearance: none !important;
  appearance: none;
}
.range {
  position: absolute;
  top: 21px;
  z-index: -2;
  display: block;
  width: 100%;
  height: 24px;
  background: #f3f3f3;
  border-radius: 5px;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.15);
  content: ' ';
}

.slider::-webkit-slider-thumb {
  width: 66px;
  height: 66px;
  background-color: transparent;
  border: transparent;
  border-radius: 2px;
  cursor: pointer;
  -webkit-appearance: none !important;
  appearance: none;
}

.slider::-moz-range-thumb {
  position: relative;
  width: 66px;
  height: 66px;
  background-color: transparent;
  border: none;
  border-radius: 2px;
  appearance: none;
}

.value,
.valueMax {
  position: absolute;
  top: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  color: #fff;
  font-weight: 500;
  font-weight: var(--bold);
  font-size: 30px;
  background-color: #688aea;
  border-radius: 2px;
}
.valueMax sup {
  font-size: 20px;
}

.image {
  position: relative;
  grid-row: 2/7;
  grid-column: 4;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
}

/* .shadow {
  position: relative;
  z-index: -1;
  grid-row: 2/7;
  grid-column: 4;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  margin: 50px;
  background: #2c2c2c;
  border-radius: 6px;
  filter: blur(90px);
} */
.square {
  position: relative;
  top: -178px;
  left: -154px;
  z-index: -1;
  grid-row: 2/7;
  grid-column: 4;
  width: 420px;
  height: 420px;
  background: rgba(176, 203, 234, 0.15);
}
.square::before {
  position: absolute;
  width: 630px;
  height: 630px;
  background-image: url('../../images/PatternBlueBig.svg');
  content: ' ';
}
.square::after {
  position: absolute;
  top: 358px;
  right: -614px;
  width: 210px;
  height: 630px;
  background-image: url('../../images/PatternBlueBig.svg');
  content: ' ';
}

@media (max-width: 1919px) {
  .container {
    grid-template-rows: 330px 118px 108px 68px 261px;
    grid-template-columns: 139px 412px 79px 560px 179px;
  }
  .header {
    font-size: 30px;
  }
  .price {
    font-size: 68px;
  }
  .text {
    margin-bottom: 62px;
    font-size: 12px;
  }
  .square {
    top: -142px;
    left: -122px;
    width: 334px;
    height: 334px;
  }
  .square::before {
    width: 528px;
    height: 528px;
    background-size: 176px;
  }
  .square::after {
    top: 258px;
    right: -500px;
    width: 176px;
    height: 528px;
    background-size: 176px;
  }
  .range {
    top: 17px;
    height: 17px;
    background: #f3f3f3;
    border-radius: 5px;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.15);
    content: ' ';
  }
  .slider::-webkit-slider-thumb {
    width: 52px;
    height: 52px;
  }
  .slider::-moz-range-thumb {
    width: 52px;
    height: 52px;
  }
  .value {
    width: 52px;
    height: 52px;
    font-size: 24px;
  }
  .priceName {
    font-size: 40px !important;
    line-height: 68px;
  }
}

@media (max-width: 1365px) {
  .container {
    grid-template-rows: 293px 86px 108px 60px 174px;
    grid-template-columns: 60px 349px 50px 433px 132px;
  }
  .header {
    font-size: 24px;
  }
  .price {
    font-size: 58px;
  }
  .text {
    font-size: 10px;
  }
  .square {
    top: -110px;
    left: -94px;
    width: 258px;
    height: 258px;
  }
  .square::before {
    width: 408px;
    height: 408px;
    background-size: 136px;
  }
  .square::after {
    top: 200px;
    right: -400px;
    width: 136px;
    height: 408px;
    background-size: 136px;
  }
  .range {
    top: 17px;
    height: 17px;
    background: #f3f3f3;
    border-radius: 5px;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.15);
    content: ' ';
  }
  .slider::-webkit-slider-thumb {
    width: 44px;
    height: 44px;
  }
  .slider::-moz-range-thumb {
    width: 44px;
    height: 44px;
  }
  .value {
    width: 44px;
    height: 44px;
    font-size: 24px;
  }
  .priceName {
    font-size: 36px !important;
    line-height: 58px;
  }
  .valueMax sup {
    font-size: 16px;
  }
}

@media (max-width: 1023px) {
  .container {
    grid-template-rows: 144px 87px 108px 60px 142px 405px;
    grid-template-columns: 197px 405px 166px;
  }
  .header,
  .price,
  .text {
    grid-column: 2;
    text-align: center;
  }
  .square {
    top: -110px;
    left: -94px;
    width: 258px;
    height: 258px;
  }
  .image {
    grid-row: 6;
    grid-column: 2;
  }
  /* .shadow {
    grid-row: 6;
    grid-column: 2;
  } */
  .square {
    top: -102px;
    left: -88px;
    grid-row: 6;
    grid-column: 2;
    width: 240px;
    height: 240px;
  }
  .square::before {
    width: 360px;
    height: 360px;
    background-size: 120px;
  }
  .square::after {
    top: 200px;
    right: -380px;
    width: 120px;
    height: 360px;
    background-size: 120px;
  }
  .range {
    top: 14px;
    height: 17px;
    background: #f3f3f3;
    border-radius: 5px;
  }
  .value {
    font-size: 18px;
  }
  .priceName {
    font-size: 36px !important;
  }
  .valueMax sup {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-rows: 87px 54px 50px 47px 90px 232px;
    grid-template-columns: 78px 232px 64px;
  }
  .header {
    font-size: 14px;
  }
  .price {
    font-size: 33px;
  }
  .image {
    grid-row: 6;
    grid-column: 2;
  }
  /* .shadow {
    grid-row: 6;
    grid-column: 2;
  } */
  .square {
    top: -58px;
    left: -50px;
    width: 138px;
    height: 138px;
  }
  .square::before {
    width: 207px;
    height: 207px;
    background-size: 69px;
  }
  .square::after {
    top: 120px;
    right: -210px;
    width: 69px;
    height: 207px;
    background-size: 69px;
  }
  .range {
    top: 10px;
    height: 12px;
    background: #f3f3f3;
    border-radius: 1px;
  }
  .slider::-webkit-slider-thumb {
    width: 32px;
    height: 32px;
  }
  .slider::-moz-range-thumb {
    width: 32px;
    height: 32px;
  }
  .value {
    width: 32px;
    height: 32px;
    font-size: 15px;
  }
  .priceName {
    font-size: 24px !important;
    line-height: 33px;
  }
  .valueMax sup {
    font-size: 10px;
  }
}
