.background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  zoom: 1;
}

.container {
  display: grid;
  grid-template-rows: 1fr max-content 1fr;
  grid-template-columns: 1fr max-content 1fr;
  height: 100vh;
}

@keyframes rect2 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes rect3 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes rect4 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes rect1 {
  0% {
    transform: scale(2);
  }
  25% {
    transform: scale(1);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
}
.preloader {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
}
.preloader rect:nth-child(1) {
  animation: rect1 1.5s ease-in-out infinite;
}
.preloader rect:nth-child(2) {
  transform-origin: 60px 0;
  animation: rect2 1.5s ease-in-out infinite;
}
.preloader rect:nth-child(3) {
  transform-origin: 60px 60px;
  animation: rect3 1.5s ease-in-out infinite;
}
.preloader rect:nth-child(4) {
  transform-origin: 0 60px;
  animation: rect4 1.5s ease-in-out infinite;
}
