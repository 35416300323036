.title {
  margin-bottom: 16px;
  font-weight: var(--bold);
  font-size: 36px;
}

.email,
.button {
  color: var(--blue);
  font-weight: var(--bold);
}

@media (max-width: 1919px) {
  .title {
    font-size: 24px;
  }

  .description {
    margin-top: 36px;
  }

  .secondDescription {
    margin-top: 60px;
  }

  .button {
    margin-left: 4px;
  }
}

@media (max-width: 1365px) {
  .title {
    font-size: 18px;
  }
  .button {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .description {
    margin-top: 12px;
  }

  .secondDescription {
    margin-top: 24px;
  }

  .button {
    margin-left: 4px;
  }
}
