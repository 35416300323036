.container {
  position: relative;
  display: grid;
  grid-template-rows: 325px 171px 16px 39px 32px 52px 222px 1fr;
  grid-template-columns: 1fr 177px 573px 48px 137px 985px 1fr;
  width: 100vw;
  color: #2c2c2c;
}

.container h1 {
  grid-row: 2;
  grid-column: 3/6;
  font-size: 48px;
  text-transform: uppercase;
}

.container p {
  grid-row: 2;
  grid-column: 5/7;
  align-self: flex-end;
  width: 170px;
  margin-bottom: -34px;
  color: rgba(44, 44, 44, 0.8);
  line-height: 20px;
  text-align: justify;
}

.pattern {
  grid-row: 4;
  grid-column: 1/4;
  background-image: url('../../images/PatternGraySmall.svg');
  background-position: 100% 100%;
}

.buttons {
  display: grid;
  grid-row: 6;
  grid-column: 3;
  grid-template-columns: 1fr 1fr;
  height: 52px;
  column-gap: 16px;
}

.buttons button {
  width: 100%;
  font-weight: 600;
}

.try {
  color: #fff;
  background-color: #688aea;
  border-radius: 2px;
  box-shadow: 0px 13px 19px rgba(68, 85, 161, 0.1);
  cursor: pointer;
}

.try:hover {
  background-color: #6977b4;
}

.look {
  color: #4455a1;
  background-color: #fff;
  border: 1px solid #4455a1;
  border-radius: 2px;
}

.look:hover {
  background-color: #e3e6f1;
}

.grayRect {
  position: relative;
  grid-row: 6/8;
  grid-column: 5/8;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: inset 0px 4px 19px rgba(0, 0, 0, 0.1);
}

.animation {
  position: absolute;
  bottom: 0;
  left: 93px;
  width: 852px;
}

@keyframes left {
  from {
    left: 0px;
  }
  to {
    left: -240px;
  }
}

@media (max-width: 1919px) {
  .container {
    grid-template-rows: 239px 129px 16px 39px 26px 39px 148px 1fr;
    grid-template-columns: 1fr 136px 429px 31px 96px 674px 1fr;
  }

  .container h1 {
    font-size: 36px;
  }

  .container p {
    width: 128px;
    margin-bottom: -16px;
    line-height: 15px;
  }

  .buttons {
    height: 39px;
  }

  .animation {
    left: 36px;
    width: 600px;
  }

  @keyframes leftDesktop {
    from {
      left: 0px;
    }
    to {
      left: -140px;
    }
  }
}

@media (max-width: 1365px) {
  .container {
    grid-template-rows: 249px 99px 16px 39px 24px 36px 134px 1fr;
    grid-template-columns: 1fr 60px 339px 16px 89px 520px 1fr;
  }

  .container h1 {
    font-size: 28px;
  }

  .container p {
    width: 106px;
    margin-bottom: -18px;
    line-height: 12px;
  }

  .animation {
    width: 480px;
  }
}

@media (max-width: 1023px) {
  .container {
    grid-template-rows: 153px 99px 16px 39px 24px 36px 203px 214px;
    grid-template-columns: 1fr 60px 339px 16px 89px 264px 1fr;
  }

  .grayRect {
    grid-row: 8;
    grid-column: 1/6;
  }

  .animation {
    left: 6.25vw;
    width: 82.29vw;
  }

  .buttons {
    width: 338px;
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-rows: 77px auto auto 25px 16px 97px 131px 125px;
    grid-template-columns: 1fr 283px 1fr;
  }
  .animation {
    left: calc(50vw - 185px);
    width: 370px;
    margin: 0 auto;
  }

  .container h1 {
    grid-row: 2;
    grid-column: 2;
    font-size: 21px;
    line-height: 25px;
  }

  .container p {
    grid-row: 3;
    grid-column: 2;
    align-self: flex-start;
    width: 202px;
    margin-bottom: 0;
    font-size: 10px;
    line-height: 12px;
  }

  .pattern {
    grid-row: 4;
    grid-column: 2/4;
    background-image: url('../../images/PatternGraySmall.svg');
    background-position: -8px 100%;
    background-size: 11px;
  }

  .buttons {
    display: grid;
    grid-row: 6;
    grid-column: 2;
    grid-template-columns: 1fr;
    width: auto;
    height: 52px;
    row-gap: 17px;
  }

  .buttons button {
    height: 40px;
  }
}
