.container {
  position: absolute;
  top: 772px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
.item {
  display: block;
  width: 12px;
  height: 12px;
  margin: 0 4px;
  background-color: #688aea;
  border-radius: 2px;
  transition: width 0.5s;
}
.current {
  width: 80px;
}

@media (max-width: 1919px) {
  .container {
    top: 570px;
  }
  .item {
    width: 8px;
    height: 8px;
    margin: 0 3px;
    border-radius: 2px;
  }
  .current {
    width: 53px;
  }
}

@media (max-width: 1365px) {
  .container {
    top: 502px;
  }
  .item {
    width: 6px;
    height: 6px;
    margin: 0 2px;
    border-radius: 1.5px;
  }
  .current {
    width: 40px;
  }
}

@media (max-width: 767px) {
  .container {
    top: 400px;
  }
}
