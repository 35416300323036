.item {
  position: relative;
  display: grid;
  align-self: center;
  width: 100%;
  height: 768px;
  border-radius: 1px;
}

.header {
  margin-bottom: 48px;
  font-size: 36px;
}

.text {
  align-self: flex-end;
  padding: 72px 64px;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
}

.list li {
  margin-bottom: 16px;
  font-size: 18px;
  list-style: none;
}

.list li:before {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 12px;
  vertical-align: middle;
  background-color: #000;
  border-radius: 2px;
  content: '';
}

.additional {
  font-size: 18px;
}

.twoColumn {
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1919px) {
  .item {
    height: 548px;
  }
  .header {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 29px;
  }
  .text {
    padding: 48px;
  }
  .list li {
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 15px;
  }
  .additional {
    margin-top: 12px;
    font-size: 12px;
  }
  .list li:before {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 8px;
    border-radius: 1.5px;
  }
}

@media (max-width: 1365px) {
  .item {
    height: 439px;
  }
  .header {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 22px;
  }
  .text {
    padding: 32px;
  }
  .list li {
    margin-bottom: 8px;
    font-size: 10px;
    line-height: 12px;
  }
  .additional {
    margin-top: 12px;
    font-size: 12px;
  }
  .list li:before {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 0.5px;
  }
}

@media (max-width: 767px) {
  .item {
    height: 368px;
  }
  .header {
    margin-bottom: 16px;
    font-size: 15px;
    line-height: 18px;
  }
  .text {
    padding: 25px;
  }
  .list li {
    margin-bottom: 2px;
    font-size: 10px;
    line-height: 12px;
  }
  .additional {
    margin-top: 6px;
    font-size: 10px;
  }
  .list li:before {
    display: inline-block;
    width: 3px;
    height: 3px;
    border-radius: 0.5px;
  }
}
