.container {
  display: grid;
  width: 1134px;
  margin: 0 auto;
  margin-top: 172px;
}

.header {
  position: relative;
  margin-left: 70px;
  color: #000;
  font-weight: var(--bold);
  font-size: 36px;
  text-align: left;
  text-transform: uppercase;
}

.header::before {
  position: absolute;
  top: -30px;
  left: -86px;
  z-index: -1;
  width: 154px;
  height: 154px;
  background-color: rgba(176, 203, 234, 0.2);
  content: ' ';
}

.clients {
  display: grid;
  grid-row-gap: 50px;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-items: center;
  margin-top: 101px;
}

@media (max-width: 1919px) {
  .container {
    width: 970px;
    margin-top: 161px;
  }
  .header {
    margin-left: 70px;
    font-size: 24px;
    text-align: left;
    text-transform: uppercase;
  }
  .header::before {
    top: -30px;
    left: -50px;
    width: 118px;
    height: 118px;
  }
}

@media (max-width: 1365px) {
  .container {
    width: 814px;
    margin-top: 172px;
  }
  .header {
    margin-left: 70px;
    font-size: 18px;
    text-align: left;
    text-transform: uppercase;
  }
  .header::before {
    top: -30px;
    left: -40px;
    width: 102px;
    height: 102px;
  }
}

@media (max-width: 1023px) {
  .container {
    width: 372px;
    margin: 0 auto;
    margin-top: 96px;
  }
  .header {
    margin-left: 0;
  }
  .clients {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
    align-items: center;
    justify-items: center;
    margin-top: 56px;
    column-gap: 20px;
  }
  .clients img {
    width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 767px) {
  .container {
    width: 332px;
  }
}
