.container {
  width: 100vw;
  padding: 218px 363px 0px;
}

.container h2 {
  position: relative;
  margin-bottom: 16px;
  font-weight: var(--bold);
  font-size: 48px;
}

.container h2::after {
  position: absolute;
  top: 0;
  left: -363px;
  width: 363px;
  height: 56px;
  background-image: url('../../images/PatternGraySmall.svg');
  content: ' ';
}

.container p {
  margin-bottom: 72px;
  font-weight: var(--light);
}

.title {
  font-weight: var(--bold);
  font-size: 36px;
}

.equipmentItem {
  margin-bottom: 72px;
}

.equipmentItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.equipmentItemBody {
  height: 0;
  overflow: hidden;
}

.equipmentItemBodyOpen {
  height: auto;
}

.stationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--bright-gray);
}

.stationItem h4 {
  margin-top: 32px;
  margin-bottom: 8px;
  font-weight: var(--bold);
  font-size: 24px;
}

.stationItem p {
  margin-bottom: 32px;
  font-weight: var(--light);
}

/* .download {
  width: 283px;
  height: 52px;
  color: #4455a1;
  font-weight: var(--medium);
  background: #fff;
  border: 1px solid #4455a1;
  border-radius: 2px;
  outline: none;
}

.download:hover {
  background: rgba(68, 85, 161, 0.15);
} */

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

.contact button {
  position: relative;
  width: 435px;
  height: 52px;
  margin-bottom: 24px;
  color: var(--white);
  font-weight: 600;
  background-color: #688aea;
  border-radius: 2px;
  outline: none;
  box-shadow: 0px 13px 19px rgba(42, 44, 246, 0.1);
}

.contact button:hover {
  background-color: #6977b4;
}

.contact button::after {
  position: absolute;
  top: 0;
  left: 100%;
  width: calc(50vw - 435px / 2);
  height: 56px;
  background-image: url('../../images/PatternGraySmall.svg');
  background-position: -11px -5px;
  content: ' ';
}

.contact p {
  color: var(--dark-gray);
  font-weight: var(--light);
}

@media (max-width: 1919px) {
  .container {
    padding: 134px 270px;
  }

  .container h2 {
    font-size: 36px;
  }

  .equipmentItem {
    margin-bottom: 48px;
  }

  .title {
    font-size: 24px;
  }

  .equipmentItemHeader img {
    width: 30px;
  }

  .stationItem h4 {
    font-size: 18px;
  }

  .stationItem p {
    margin-bottom: 24px;
  }

  /* .download {
    width: 211px;
    height: 39px;
  } */

  .contact button {
    width: 297px;
    height: 39px;
  }

  .contact button::after {
    left: 100%;
    width: calc(50vw - 297px / 2);
    height: 39px;
    background-position: 11px;
    background-size: 16px;
  }
}

@media (max-width: 1365px) {
  .container {
    padding: 104px 175px;
  }

  .container h2 {
    font-size: 24px;
  }

  .container p {
    margin-bottom: 40px;
  }

  .equipmentItem {
    margin-bottom: 32px;
  }

  .title {
    font-size: 18px;
  }

  .equipmentItemHeader img {
    width: 24px;
  }

  .stationItem h4 {
    font-size: 14px;
  }

  .stationItem p {
    margin-bottom: 22px;
  }

  /* .download {
    width: 174px;
    height: 36px;
  } */

  .contact button {
    width: 256px;
    height: 37px;
  }
}

@media (max-width: 1023px) {
  .container {
    padding: 104px 60px;
  }

  .equipmentItemHeader img {
    width: 24px;
  }
  .contact button::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 72px 20px 20px;
  }

  .stationItem {
    flex-direction: column;
    align-items: flex-start;
  }

  /* .download {
    width: 100%;
    margin-bottom: 16px;
  } */

  .contact button {
    width: 100%;
    border-radius: 8px;
  }
}
