.container {
  position: relative;
  z-index: -2;
  height: fit-content;
  padding: 61px;
  background-color: #f9f9f9;
  background-image: url('../../images/PatternBlueSmall.svg');
  background-repeat: repeat-x;
  background-position: 5px calc(100% - 6px);
  border-radius: 1px;
}

.container::after {
  position: absolute;
  bottom: -28px;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 28px;
  background-image: url('../../images/PatternGraySmall.svg');
  background-position: 5px 100%;
  content: ' ';
}

.logo {
  position: absolute;
  top: -61px;
  left: -128px;
  width: 189px;
}

.item:not(:last-child) {
  margin-bottom: 48px;
}

.title {
  font-weight: 600;
  font-size: 24px;
}

.description {
  margin-top: 8px;
}

@media (max-width: 1919px) {
  .container {
    padding: 46px;
  }

  .item:not(:last-child) {
    margin-bottom: 32px;
  }

  .logo {
    top: -45px;
    left: -95px;
    width: 140px;
  }

  .title {
    font-size: 18px;
  }

  .container {
    background-size: 12.75px;
  }

  .container::after {
    bottom: -20.18px;
    height: 20.18px;
    background-size: 12.75px;
  }
}

@media (max-width: 1365px) {
  .container {
    padding: 48px;
  }

  .logo {
    top: -26px;
    left: -58px;
    width: 106px;
  }

  .title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 48px;
  }

  .logo {
    top: -44px;
    left: 19px;
  }

  .title {
    font-size: 14px;
  }
}
