.container {
  display: grid;
  grid-template-rows: 768px;
  grid-template-columns: 1fr 939px;
  margin-top: 223px;
  column-gap: 64px;
}

.text {
  z-index: -2;
  display: grid;
  grid-template-rows: 232px 150px 154px;
  grid-template-columns: 105px 435px 1fr;
  background-color: #f9f9f9;
}

.image {
  grid-row: 1;
  grid-column: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: -5px 0;
  box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.08);
}

.header {
  position: relative;
  grid-row: 2;
  grid-column: 2;
  margin-bottom: 32px;
  font-size: 48px;
  text-transform: uppercase;
}

.header::after {
  position: absolute;
  top: 73px;
  left: 300px;
  z-index: -1;
  display: block;
  width: 308px;
  height: 308px;
  background: rgba(176, 203, 234, 0.5);
  content: ' ';
}

.description {
  grid-row: 3;
  grid-column: 2;
  font-size: 18px;
  text-align: justify;
}

@media (max-width: 1919px) {
  .container {
    grid-template-rows: 572px;
    grid-template-columns: 1fr 611px;
    margin-top: 118px;
    column-gap: 72px;
  }
  .text {
    grid-template-rows: 185px 112px 90px;
    grid-template-columns: 72px 330px 1fr;
  }
  .header {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 44px;
  }
  .description {
    font-size: 12px;
    line-height: 15px;
  }
  .header::after {
    top: 54px;
    left: 227px;
    width: 229px;
    height: 229px;
  }
}

@media (max-width: 1365px) {
  .container {
    grid-template-rows: 475px;
    grid-template-columns: 1fr 464px;
    margin-top: 150px;
    column-gap: 56px;
  }
  .text {
    grid-template-rows: 159px 84px 72px;
    grid-template-columns: 56px 250px 1fr;
  }
  .header {
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 34px;
  }
  .description {
    font-size: 10px;
    line-height: 12px;
  }
  .header::after {
    top: 41px;
    left: 183px;
    width: 165px;
    height: 165px;
  }
}

@media (max-width: 1023px) {
  .container {
    grid-template-rows: 77px 409px;
    grid-template-columns: 1fr 648px 1fr;
    margin-top: 134px;
    row-gap: 50px;
  }
  .text {
    grid-column: 2;
    grid-template-rows: 41px 36px;
    grid-template-columns: 1fr;
    align-self: flex-start;
    justify-self: center;
    width: 487px;
    text-align: center;
    background-color: transparent;
  }
  .header {
    grid-row: 1;
    font-size: 24px;
    line-height: 29px;
    column-gap: 0;
  }
  .header::after {
    display: none;
  }
  .description {
    grid-row: 2;
    text-align: center;
  }
  .image {
    grid-row: 2;
    grid-column: 2;
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-rows: 109px 273px;
    grid-template-columns: 1fr;
    gap: 0;
    margin-top: 134px;
  }
  .text {
    grid-column: 1;
    grid-template-rows: 49px 60px;
    width: 100vw;
    text-align: center;
    background-color: transparent;
  }
  .header {
    grid-row: 1;
    grid-column: 1;
    font-size: 24px;
    line-height: 29px;
  }
  .header::after {
    display: none;
  }
  .description {
    grid-column: 1;
    justify-self: center;
    width: 336px;
    text-align: center;
  }
  .image {
    grid-column: 1;
    width: 100vw;
    margin-top: 20px;
  }
}
