.container {
  right: 0;
  left: 0;
}

.header {
  width: 1194px;
  margin: 0 auto;
  font-size: 36px;
}

.form {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 1194px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 56px;
  column-gap: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 13px 19px rgba(0, 0, 0, 0.1);
  row-gap: 24px;
}

.square {
  position: absolute;
  top: 198px;
  right: calc(50vw - 661px);
  width: 288px;
  height: 288px;
  background-color: rgba(176, 203, 234, 0.5);
  border-radius: 3px;
  content: ' ';
}

.button {
  grid-row: 3;
}

@media (max-width: 1919px) {
  .form {
    width: 824px;
    margin-top: 32px;
    padding: 32px;
    column-gap: 16px;
    row-gap: 16px;
  }
  .header {
    width: 824px;
    font-size: 24px;
  }
  .square {
    position: absolute;
    top: 146px;
    right: calc(50vw - 460px);
    width: 234px;
    height: 234px;
    background-color: rgba(176, 203, 234, 0.5);
    border-radius: 3px;
    content: ' ';
  }
}

@media (max-width: 1365px) {
  .container {
    top: 359px;
  }
  .form {
    width: 674px;
    margin-top: 24px;
    padding: 24px 16px;
  }
  .header {
    width: 674px;
    font-size: 18px;
  }
  .square {
    display: none;
  }
}

@media (max-width: 1023px) {
  .form {
    grid-template-columns: 1fr 1fr;
    width: 444px;
    column-gap: 8px;
  }
  .header {
    width: 444px;
    text-align: center;
  }

  .button {
    grid-row: 4;
  }
}

@media (max-width: 767px) {
  .container {
    top: 0;
  }
  .form {
    grid-template-columns: 1fr;
    width: calc(100% - 40px);
    padding: 32px;
    column-gap: 8px;
  }

  .header {
    width: calc(100% - 40px);
  }

  .button {
    grid-row: 6;
    margin-top: 12px;
  }
}
