.video {
  width: 1020px;
  /* align-self: center; */
  /* justify-self: center; */
}

@media (max-width: 1919px) {
  .video {
    width: 720px;
  }
}

@media (max-width: 1365px) {
  .video {
    width: 640px;
  }
}

@media (max-width: 1023px) {
  .video {
    width: 400px;
  }
}

@media (max-width: 767px) {
  .video {
    width: 250px;
  }
}
