.form {
  display: grid;
  grid-row: 3;
  grid-row-gap: 24px;
  grid-column: 1/3;
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1fr;
}

.title {
  grid-row: 1;
  grid-column: 1;
  font-size: 36px;
}

.description {
  grid-row: 2;
  grid-column: 1;
}

.button {
  grid-row: 5;
}

.textRedirect {
  grid-row: 5;
  grid-column: 2;
  align-self: center;
  justify-self: center;
}

.textRedirect button {
  margin-left: 8px;
  color: var(--blue);
  font-weight: var(--bold);
  outline: none;
}

@media (max-width: 1919px) {
  .title {
    font-size: 24px;
  }
  .form {
    grid-row-gap: 16px;
    grid-column-gap: 32px;
  }
}

@media (max-width: 1365px) {
  .title {
    font-size: 18px;
  }
  .form {
    grid-column-gap: 0;
  }
}

@media (max-width: 767px) {
  .form {
    grid-column: 1;
    grid-template-columns: 1fr;
  }

  .button {
    grid-row: 8;
  }

  .textRedirect {
    grid-row: 9;
    grid-column: 1;
    justify-self: left;
  }

  .description {
    max-width: 255px;
  }
}
