.container {
  display: grid;
  grid-template-rows: 257px 166px 768px;
  grid-template-columns: 1fr 308px 490px 1122px 1fr;
}

.header {
  grid-row: 2;
  grid-column: 4;
}

.body {
  grid-row: 3;
  grid-column: 3;
}

.image {
  display: block;
  grid-row: 3;
  grid-column: 4/6;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* align-self: flex-start; */
  object-position: 0 0;
  border-radius: 4px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
}

/* .shadow {
  z-index: -1;
  display: block;
  grid-row: 3;
  grid-column: 4;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  margin: 40px;
  background-color: #2c2c2c;
  border-radius: 6px;
  filter: blur(90px);
} */

@media (max-width: 1919px) {
  .container {
    grid-template-rows: 196px 138px 572px;
    grid-template-columns: 1fr 234px 365px 667px 1fr;
  }
}

@media (max-width: 1365px) {
  .container {
    grid-template-rows: 273px 108px 572px;
    grid-template-columns: 1fr 118px 324px 582px 1fr;
  }
}

@media (max-width: 1023px) {
  .container {
    grid-template-rows: 50px 80px 412px;
    grid-template-columns: 1fr 105px 301px 362px 1fr;
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-rows: 26px 43px min-content;
    grid-template-columns: 1fr 17px 174px 184px 1fr;
  }
  .image {
    display: none;
  }
  .body {
    grid-column: 3/5;
  }
}
