:root {
  --light: 300;
  --medium: 600;
  --bold: 700;

  --gray: #f9f9f9;
  --light-gray: rgba(255, 255, 255, 0.9);
  --bright-gray: #c4c4c4;
  --dark-gray: #646464;
  --light-black: #2c2c2c;
  --transparent-gray: rgba(255, 255, 255, 0.3);
  --hovered-gray: rgba(255, 255, 255, 0.1);
  --white: #ffffff;
  --black: #000000;
  --blue: #4455a1;
  --light-blue: #d2dcf9;
  --hovered-blue: rgba(210, 220, 249, 0.6);
  --dark-blue: #313c6f;
  --green: #3cb276;
  --red: #d94061;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  min-width: 320px;
  overflow-x: hidden;
  overflow-x: hidden;
  font-weight: var(--light);
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input {
  font-size: inherit;
  font-family: inherit;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

@media (max-width: 1919px) {
  body {
    font-size: 12px;
  }
}

@media (max-width: 1365px) {
  body {
    font-size: 10px;
  }
}
