.form {
  display: grid;
  grid-row: 2;
  grid-row-gap: 24px;
  grid-column: 1;
  grid-column-gap: 40px;
  grid-template-rows: 52px 52px 52px;
  grid-template-columns: 1fr 1fr 1fr;
}

.optionForm {
  grid-template-rows: 52px 52px 88px 52px;
}

.title {
  grid-row: 1;
  grid-column: 1;
  font-size: 36px;
}

.button {
  grid-column: 1;
}

.optionsContainer {
  display: grid;
  grid-row: 3;
  grid-row-gap: 16px;
  grid-column: 1/3;
  grid-column-gap: 40px;
  grid-template-rows: 20px 52px;
  grid-template-columns: 1fr 1fr 1fr;
}

.optionsContainer label {
  font-weight: var(--bold);
}

@media (max-width: 1919px) {
  .form {
    grid-column-gap: 16px;
    grid-template-rows: 39px 39px 39px;
  }
  .title {
    font-size: 24px;
  }
  .optionForm {
    grid-template-rows: 39px 39px 62px 39px;
  }

  .optionsContainer {
    grid-row-gap: 10px;
    grid-column-gap: 16px;
  }
}

@media (max-width: 1023px) {
  .title {
    font-size: 18px;
  }
  .form {
    grid-row-gap: 16px;
    grid-template-rows: 36px 36px 36px;
    grid-template-columns: 1fr 1fr;
  }

  .optionsContainer {
    grid-row: 4;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .helpLabel {
    grid-row: 3;
    grid-column: 1;
  }

  .helpInput {
    grid-row: 4;
    grid-column: 1;
  }
}

@media (max-width: 767px) {
  .title {
    width: 80%;
  }
  .form {
    grid-template-columns: 1fr;
  }

  .optionsContainer {
    grid-row: 6;
    grid-column: 1;
    grid-template-columns: 1fr;
  }

  .placeLabel {
    grid-row: 3;
  }

  .helpLabel {
    grid-row: 5;
  }

  .numberInput {
    grid-row: 2;
  }

  .placeInput {
    grid-row: 4;
  }

  .helpInput {
    grid-row: 6;
  }
}
