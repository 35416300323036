.item {
  position: relative;
  display: block;
  margin-bottom: 12px;
  color: #959595;
  font-weight: var(--light);
  font-size: 24px;
  list-style: none;
  cursor: pointer;
}

.item:hover {
  color: #000;
  font-weight: var(--medium);
}

.item:hover::before {
  position: absolute;
  left: -6px;
  z-index: -1;
  display: block;
  width: 10px;
  height: 10px;
  background-color: #688aea;
  border-radius: 1px;
  content: ' ';
}

.active {
  display: none;
}

@media (max-width: 1919px) {
  .item {
    font-size: 14px;
  }
  .item:hover::before {
    left: -4px;
    width: 8px;
    height: 8px;
  }
}
@media (max-width: 1365px) {
  .item {
    font-size: 12px;
  }
  .item:hover::before {
    left: -3px;
    width: 6px;
    height: 6px;
  }
}

@media (max-width: 767px) {
  .item {
    color: #000;
    font-size: 10px;
  }
}
