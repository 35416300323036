.form {
  display: grid;
  grid-row-gap: 40px;
  grid-template-rows: 52px 52px;
  grid-template-columns: 1fr;
}

.title {
  grid-row: 1;
  grid-column: 1;
  font-size: 36px;
}

.button {
  grid-row: 2;
}

@media (max-width: 1919px) {
  .title {
    font-size: 24px;
  }
  .form {
    grid-row-gap: 32px;
    grid-template-rows: 39px 39px;
  }
}

@media (max-width: 1919px) {
  .title {
    font-size: 18px;
  }
  .form {
    grid-row-gap: 24px;
    grid-template-rows: 36px 36px;
  }
}
