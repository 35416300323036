.container {
  position: relative;
  display: grid;
  grid-template-rows: 428px 570px 40px 52px;
  grid-template-columns: 464px 570px 72px 573px 241px;
  justify-content: center;
}

.container::after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 207px;
  height: 207px;
  background-image: url('../../images/PatternBlueMonitoring.svg');
  background-position: 100%;
  content: ' ';
}

.cow {
  position: relative;
  grid-row: 2;
  grid-column: 2;
  background-image: url('../../images/ProductCow.png');
  background-size: cover;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
}

.cow::before {
  position: absolute;
  top: -146px;
  left: -128px;
  z-index: -1;
  width: 480px;
  height: 480px;
  background-color: rgba(176, 203, 234, 0.15);
  content: ' ';
}
.cow::after {
  position: absolute;
  top: -226px;
  left: -287px;
  width: 299px;
  height: 238px;
  background-image: url('../../images/ProductMonitoring.svg');
  content: ' ';
}

.learnMore {
  grid-row: 4;
  grid-column: 2;
  color: #4455a1;
  font-weight: var(--medium);
  background-color: #fff;
  border: 1px solid #4455a1;
  border-radius: 5px;
  outline: none;
}

.learnMore:hover {
  background-color: rgba(68, 85, 161, 0.15);
}

.companies {
  grid-row: 2;
  grid-column: 4;
}

.companiesItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 44px;
}

.companiesItem h3 {
  margin-bottom: 16px;
  color: var(--black);
  font-weight: var(--bold);
  font-size: 24px;
}

.companiesItem p {
  margin-bottom: 16px;
  color: var(--black);
  font-weight: var(--light);
}

.getOffer {
  color: #4455a1;
  font-weight: 600;
  outline: none;
}

@media (max-width: 1919px) {
  .container {
    grid-template-rows: 283px 425px 32px 39px;
    grid-template-columns: 359px 425px 72px 418px 92px;
  }
  .container::after {
    width: 114px;
    height: 114px;
    background-size: 38px;
  }
  .cow::before {
    top: -117px;
    left: -102px;
    width: 384px;
    height: 384px;
  }
  .cow::after {
    top: -173px;
    left: -226px;
    width: 236px;
    height: 183px;
    background-size: 236px 183px;
  }
  .companiesItem {
    margin-bottom: 37px;
  }
  .companiesItem h3 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .companiesItem p {
    margin-bottom: 10px;
    font-size: 12px;
  }
  .getOffer {
    font-size: 12px;
  }
}

@media (max-width: 1365px) {
  .container {
    grid-template-rows: 296px 328px 24px 36px;
    grid-template-columns: 258px 328px 48px 329px 61px;
  }
  .container::after {
    width: 72px;
    height: 72px;
    background-size: 24px;
  }
  .cow::before {
    top: -108px;
    left: -84px;
    width: 308px;
    height: 308px;
  }
  .cow::after {
    top: -155px;
    left: -198px;
    width: 206px;
    height: 163px;
    background-size: 206px 163px;
  }
  .companiesItem {
    margin-bottom: 25px;
  }
  .companiesItem h3 {
    margin-bottom: 8px;
    font-size: 14px;
  }
  .companiesItem p {
    margin-bottom: 8px;
    font-size: 10px;
  }
  .getOffer {
    font-size: 10px;
  }
}

@media (max-width: 1023px) {
  .container {
    grid-template-rows: 276px 328px 46px max-content 36px;
    grid-template-columns: 1fr 198px 328px 1fr;
  }
  .container::after {
    display: none;
  }
  .cow {
    position: relative;
    grid-row: 2;
    grid-column: 3;
    background-image: url('../../images/ProductCow.png');
    background-size: cover;
  }
  .cow::before {
    top: -108px;
    left: -84px;
    width: 308px;
    height: 308px;
  }
  .cow::after {
    top: -155px;
    left: -198px;
    width: 206px;
    height: 163px;
    background-size: 206px 163px;
  }
  .companies {
    grid-row: 4;
    grid-column: 2/4;
  }
  .companiesItem {
    margin-bottom: 25px;
  }
  .learnMore {
    grid-row: 5;
    grid-column: 2/4;
    width: 328px;
  }
}

@media (max-width: 767px) {
  .container {
    grid-template-rows: 276px 245px 46px max-content 36px;
    grid-template-columns: 1fr 36px 245px 42px 1fr;
  }
  .cow {
    position: relative;
    grid-row: 2;
    grid-column: 3;
    background-image: url('../../images/ProductCow.png');
    background-size: cover;
  }
  .cow::before {
    top: -80px;
    left: -44px;
    width: 230px;
    height: 230px;
  }
  .cow::after {
    top: -130px;
    left: -20px;
    width: 128px;
    height: 102px;
    background-size: 128px 102px;
  }
  .companies {
    grid-row: 4;
    grid-column: 2/5;
  }
  .companiesItem {
    margin-bottom: 25px;
  }
  .learnMore {
    width: 324px;
  }
}
