.container {
  position: relative;
  display: grid;
  grid-template-rows: max-content max-content max-content;
  grid-template-columns: 120px 1fr;
  text-align: justify;
}

.photo {
  z-index: 1;
  display: none;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.name {
  z-index: 1;
  grid-row: 1;
  grid-column: 1/3;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 24px;
  text-align: left;
}
.company {
  z-index: 1;
  grid-row: 2;
  grid-column: 1/3;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
}
.review {
  position: relative;
  grid-row: 3;
  grid-column: 1/3;
}
.review::before {
  position: absolute;
  top: -183px;
  left: -61px;
  z-index: 0;
  display: block;
  width: 124px;
  height: 124px;
  background-image: url('../../images/ReviewPattern.svg');
  content: ' ';
}

@media (max-width: 1919px) {
  .container {
    /* grid-template-rows: 15px 30px 55px; */
    grid-template-columns: 100px 1fr;
  }
  .name {
    font-size: 18px;
  }
  .photo {
    width: 76px;
    height: 76px;
  }
  .review::before {
    top: -140px;
    left: -38px;
    width: 80px;
    height: 80px;
    background-size: 80px;
  }
}

@media (max-width: 1365px) {
  .container {
    /* grid-template-rows: 14px 25px 51px; */
    grid-template-columns: 90px 1fr;
  }
  .name {
    font-size: 14px;
  }
  .photo {
    width: 66px;
    height: 66px;
  }
  .name {
    font-size: 14px;
    line-height: 17px;
  }
  .company {
    font-size: 10px;
    line-height: 12px;
  }
  .review::before {
    top: -136px;
    left: -43px;
    width: 92px;
    height: 92px;
    background-size: 92px;
  }
}
