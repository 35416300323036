.container {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 1;
  max-width: 768px;
  padding: 30px;
  font-weight: 500;
  font-size: 18px;
  background-color: var(--white);
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header > *:nth-child(2) {
  width: 100%;
  margin-left: 5px;
}

.title {
  font-weight: 700;
  font-size: 22px;
}

.closeBtn {
  position: relative;
  top: 0;
  right: 0;
}

.text {
  width: 555px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.link {
  color: var(--sky);
}

.btn {
  font-size: 16px;
}

@media (max-width: 1365px) {
  .container {
    max-width: 375px;
    padding: 20px;
    font-size: 10px;
  }

  .text {
    width: 312px;
    margin-bottom: 20px;
  }

  .btn {
    width: 100%;
    font-size: 10px;
  }

  .title {
    font-weight: 600;
    font-size: 14px;
  }
}
