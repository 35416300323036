.wrapper {
  position: relative;
}
.container {
  position: relative;
  display: flex;
  width: 390px;
  height: 52px;
}
.smallContainer {
  width: 334px;
}
.input {
  width: 338px;
  height: 100%;
  padding: 16px;
  color: #2c2c2c;
  font-weight: var(--light);
  border: 1px solid #e5e5e5;
  border-right: none;
  border-radius: 2px 0 0 2px;
  outline: none;
  cursor: text;
}
.input:focus {
  padding: 20px 16px 11px 16px;
  font-weight: var(--light);
  font-size: 16px;
  border: 1px solid var(--green);
  border-right: none;
}

.smallInput {
  width: 100%;
  border-right: 1px solid #e5e5e5;
  border-radius: 2px;
}

.filledInput {
  padding: 24px 16px 11px 16px;
  font-weight: var(--bold);
  font-size: 14px;
}

.smallInput:focus {
  border-right: 1px solid var(--green);
}

.incorrectInput {
  border: 1px solid var(--red);
}

.placeholder {
  position: absolute;
  top: 4px;
  left: 16px;
  color: #c4c4c4;
  font-weight: var(--light);
  font-size: 10px;
  pointer-events: none;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 0 5px 5px 0;
}

.incorrectIcon {
  background-color: var(--red);
  border: 1px solid var(--red);
}

.focusedIcon {
  background-color: var(--white);
  border: 1px solid var(--green);
}

.passwordIcon {
  cursor: pointer;
}

.errorContainer {
  position: absolute;
}
.errorContainer p {
  color: var(--red);
  font-size: 12px;
}

@media (max-width: 1919px) {
  .container {
    width: 255px;
    height: 39px;
  }

  .smallContainer {
    width: 235px;
  }

  .icon {
    width: 39px;
  }
  .icon svg {
    width: 11px;
    height: 11px;
  }

  .placeholder {
    font-size: 8px;
  }

  .input {
    width: 216px;
  }

  .filledInput {
    font-size: 10px;
  }

  .errorContainer p {
    font-size: 10px;
  }

  .smallInput {
    width: 235px;
  }
}

@media (max-width: 1365px) {
  .container {
    width: 224px;
    height: 36px;
  }

  .smallContainer {
    width: 203px;
  }

  .icon {
    width: 36px;
  }

  .input {
    width: 188px;
    padding: 12px;
  }
  .input:focus {
    font-size: 12px;
  }

  .smallInput {
    width: 203px;
  }

  .placeholder {
    top: 6px;
    left: 12px;
  }

  .filledInput {
    padding: 17px 16px 7px 12px;
  }

  .input:focus {
    padding: 17px 16px 7px 12px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
  }

  .input {
    width: 100%;
  }
}
