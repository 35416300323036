.container {
  position: relative;
  display: grid;
  grid-template-rows: 318px 59px 50px 570px;
  grid-template-columns: 1fr 103px 195px 822px 1.5fr;
}

.container h1 {
  position: relative;
  grid-row: 2;
  grid-column: 2/4;
  margin-bottom: 50px;
  font-size: 48px;
  text-transform: uppercase;
}

.container h1::after {
  position: absolute;
  top: -80px;
  left: 350px;
  display: block;
  width: 420px;
  height: 210px;
  background-image: url('../../images/PatternBlueBig.svg');
  content: ' ';
}

.video {
  position: relative;
  grid-row: 4;
  grid-column: 3/5;
  width: 100%;
}

.square {
  position: relative;
  top: -178px;
  left: -163px;
  z-index: -1;
  grid-row: 4;
  grid-column: 3;
  width: 420px;
  height: 420px;
  background: rgba(176, 203, 234, 0.15);
}

.square::before {
  position: absolute;
  top: 210px;
  width: 210px;
  height: 420px;
  background-image: url('../../images/PatternBlueBig.svg');
  content: ' ';
}

.square::after {
  position: absolute;
  top: 310px;
  left: 960px;
  z-index: -1;
  width: 420px;
  height: 630px;
  background-image: url('../../images/PatternBlueBig.svg');
  content: ' ';
}

@media (max-width: 1919px) {
  .container h1 {
    font-size: 36px;
  }
}

@media (max-width: 1365px) {
  .container {
    grid-template-rows: 306px 44px 50px 372px;
    grid-template-columns: 1fr 71px 152px 511px 1.5fr;
  }

  .container h1::after {
    top: -50px;
    left: 270px;
    width: 276px;
    height: 138px;
    background-size: 140px;
  }

  .square {
    top: -137px;
    left: -149px;
    width: 300px;
    height: 300px;
  }

  .square::before {
    top: 190px;
    left: 20px;
    width: 138px;
    height: 276px;
    background-size: 140px;
  }

  .square::after {
    top: 225px;
    left: 660px;
    width: 276px;
    height: 414px;
    background-size: 140px;
  }
}

@media (max-width: 1023px) {
  .container {
    grid-template-rows: 90px 29px 36px 308px 121px;
    grid-template-columns: 1fr 200px 150px 200px 1fr;
  }

  .container h1 {
    grid-row: 2;
    grid-column: 3;
    font-size: 24px;
  }

  .container h1::after {
    display: none;
  }

  .video {
    grid-row: 4;
    grid-column: 2/5;
  }

  .square {
    grid-row: 4;
    grid-column: 2;
    background-color: transparent;
  }

  .square::before {
    top: 35px;
    left: 46px;
    width: 280px;
    height: 420px;
  }

  .square::after {
    top: 125px;
    left: 520px;
    width: 280px;
    height: 420px;
  }
}

@media (max-width: 767px) {
  .container h1 {
    text-transform: none;
  }

  .container {
    grid-template-rows: 70px 29px 30px 161px 101px;
    grid-template-columns: 1fr 69px 150px 69px 1fr;
  }

  .square {
    top: 0;
    left: 0;
  }

  .square::before {
    top: -50px;
    left: -53px;
    width: 150px;
    height: 225px;
    background-size: 75px;
  }

  .square::after {
    top: 65px;
    left: 190px;
    width: 150px;
    height: 150px;
    background-size: 75px;
  }
}
