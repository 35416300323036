.button {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-image: url('../../images/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (max-width: 1919px) {
  .button {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 1365px) {
  .button {
    top: 35px;
    right: 35px;
  }
}

@media (max-width: 1023px) {
  .button {
    width: 12px;
  }
}

@media (max-width: 767px) {
  .button {
    top: 28px;
    right: 28px;
    width: 15px;
  }
}
